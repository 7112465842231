
  import { defineComponent, onMounted, computed, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import Multiselect from '@vueform/multiselect';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import {
    BannerAssetsObject,
    MerchantObject,
  } from '@/store/modules/Assets/BannerModule';

  export default defineComponent({
    name: 'banner-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const selectedBanner = ref<string>('');
      const selectedSlug = ref<string>('');
      const selectedScreen = ref<string>('');
      const loading = ref(false);
      const isRelatedId = ref<boolean>(false);
      const { can } = useAbility();
      const thumbnail = ref('/media/avatars/blank-image.jpg');
      const selectedPropertyCategory = ref(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const previewImage = (e) => {
        const file = e.target.files[0];
        thumbnail.value = URL.createObjectURL(file);
      };
      const bannerSchema = Yup.object().shape({
        type: Yup.string().required(() => {
          translate('BANNER_TYPE_IS_REQUIRED_FIELD');
        }),
        screen: Yup.string().when('type', {
          is: 'internal',
          then: Yup.string().required(() =>
            translate('SCREEN_BANNER_IS_REQUIRED_FIELD')
          ),
        }),
        slug: Yup.string().when('type', {
          is: 'internal',
          then: Yup.string().required(() =>
            translate('ACTION_BANNER_IS_REQUIRED_FIELD')
          ),
        }),
        externalLinkEn: Yup.string().when('type', {
          is: 'external',
          then: Yup.string().required(() =>
            translate('EXTERNAL_LINK_EN_IS_REQUIRED_FIELD')
          ),
        }),
        externalLinkAr: Yup.string().when('type', {
          is: 'external',
          then: Yup.string().required(() =>
            translate('EXTERNAL_LINK_AR_IS_REQUIRED_FIELD')
          ),
        }),
        externalLinkWebEn: Yup.string().when('type', {
          is: 'external',
          then: Yup.string().required(() =>
            translate('EXTERNAL_LINK_WEB_EN_IS_REQUIRED_FIELD')
          ),
        }),
        externalLinkWebAr: Yup.string().when('type', {
          is: 'external',
          then: Yup.string().required(() =>
            translate('EXTERNAL_LINK_WEB_AR_IS_REQUIRED_FIELD')
          ),
        }),
        propertyCategoryId: Yup.number().when('slug', {
          is: 'ad',
          then: Yup.number().required(() =>
            translate('PROPERTY_CATEGORY_IS_REQUIRED_FIELD').label(
              'propertyCategoryId'
            )
          ),
        }),

        entityId: Yup.number().when('slug', {
          is: 'ad_details',
          then: Yup.number().required(() =>
            translate('ENTITY_ID_IS_REQUIRED_FIELD')
          ),
        }),

        // logo: Yup.mixed().when('slug', {
        //   is: 'ad_details',
        // .required(() => translate('LOGO_IS_REQUIRED_FIELD')),
      });

      const fireError = () => {
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        Swal.fire({
          text: translate(error[0]),
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: translate('tryAgainExcl'),
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger',
          },
        });
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const logoFormData = new FormData();
        // eslint-disable-next-line
        logoFormData.append('images', values.logo);

        if (selectedSlug.value === 'merchant') {
          values.entityId = selectedMerchant.value;
        } else {
          values.entityId && (values.entityId = parseInt(values.entityId));
        }
        delete values.logo;

        const banner = await store.dispatch(Actions.CREATE_BANNER, values);
        if (!banner?.id) {
          fireError();
        } else {
          if (selectedScreen.value != 'ad') {
            const logo = await store.dispatch(Actions.UPLOAD_BANNER_IMAGE, {
              data: logoFormData,
              bannerId: banner.id,
            });
            if (logo) {
              Swal.fire({
                text: translate('SUCCESSFULLY_CREATED_BANNER'),
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: translate('ok'),
                customClass: {
                  confirmButton: 'btn btn-light-primary',
                },
              }).then(() => {
                router.push({ name: 'banners-listing' });
              });
            } else {
              fireError();
            }
          } else {
            Swal.fire({
              text: translate('SUCCESSFULLY_CREATED_BANNER'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            }).then(() => {
              router.push({ name: 'banners-listing' });
            });
          }
        }
      };
      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('bannerCreating'), [
          translate('assets'),
        ]);
      });
      await store.dispatch(Actions.GET_BANNER_ASSETS);
      await store.dispatch(Actions.GET_MERCHANT, { limit: 50 });
      const bannersAssets = ref<BannerAssetsObject>(
        store.getters.bannerAssetsList
      );
      const merchants = computed(() => store.getters.merchantList);

      const selectedMerchant = ref<MerchantObject>(
        merchants.value.length > 0 ? merchants.value[0].id : 0
      );
      const initialMerchantValue = ref(selectedMerchant);
      const ActionBannerOnChange = () => {
        bannersAssets.value.typeSlug.find((slug) => {
          return (
            slug.id === selectedSlug.value &&
            (isRelatedId.value = slug.isRelatedID)
          );
        });
      };
      const ScreenBannerOnChange = () => {
        bannersAssets.value.scrrens.find((slug) => {
          return slug.id === selectedScreen.value;
        });
      };
      await store.dispatch(Actions.GET_ALL_PROPERTY_CATEGORIES_BY_AD_TYPE_ID, {
        adType: 1,
      });

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        propertyTypesCategories: store.getters.propertyAllCategoryList,
        bannersAssets,
        merchants,
        onSubmitCreate,
        submitButton,
        translate,
        bannerSchema,
        goBack,
        selectedBanner,
        selectedSlug,
        selectedScreen,
        selectedMerchant,
        can,
        loading,
        ActionBannerOnChange,
        ScreenBannerOnChange,
        isRelatedId,
        previewImage,
        thumbnail,
        initialMerchantValue,
        selectedPropertyCategory,
      };
    },
  });
